/* base searchbar styles. */
.arc-Searchbar {
    width: 100%;
}

/* basic input styles. */
.arc-Searchbar-search__field {
    width: 100%;
    height: 40px;
    max-height: 40px;
    font-family: Roboto;
    font-size: 14px;
    padding: 0 16px;
    color: #152935;
    box-sizing: border-box;
    transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
    border: solid 1px #c9d9e8;
    outline: none;
}

/* enabled searchbar input styles. */
.arc-Searchbar-enabled .arc-Searchbar-search__field {
    background: #f2f5f8;
}

/* disabled searchbar input styles. */
.arc-Searchbar-disabled .arc-Searchbar-search__field {
    /* TODO: this color is not defined in the design system */
    background: #fafbfd;
    color: #c1c1c4;
    border: solid 1px #c9d9e8;
    cursor: not-allowed;
}

/* icon container background change on input hover. */
.arc-Searchbar-container:not(.arc-Searchbar-container-error):not(.arc-Searchbar-container-disabled):hover
    .arc-Searchbar-icon-container:before {
    opacity: 1;
}

/* 
 * input wrapper has a relative position to 
 * correctly display the placeholder. 
 */
.arc-Searchbar-selection__rendered {
    position: relative;
}

/* placeholder basic styles. */
.arc-Searchbar-selection__placeholder {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    font-size: 14px;
}

/* disabled placeholder text color. */
.arc-Searchbar-disabled .arc-Searchbar-selection__placeholder {
    color: #c1c1c4;
}

/* enabled placeholder text color. */
.arc-Searchbar-enabled .arc-Searchbar-selection__placeholder {
    color: #9da6ad;
}

/* input border on focus and hover states. */
.arc-Searchbar-enabled .arc-Searchbar-search__field:hover,
.arc-Searchbar-enabled .arc-Searchbar-search__field:focus {
    border: solid 1px #0090d1;
}

/* hide values mirror. */
.arc-Searchbar-search__field__mirror {
    display: none;
}

/* basic icon container styles. */
.arc-Searchbar-icon-container {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    max-height: 40px;
    border-radius: 0 2px 2px 0;
    color: #fff;
    position: relative;
}

/* helper to get a smooth gradient to solid color animation. */
.arc-Searchbar-icon-container:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* used to actually make the element visible. */
    content: "";
    background: #00c3ea;
    border-radius: 0 2px 2px 0;
    opacity: 0;
    transition: opacity 0.3s ease;
}

/* enabled icon container styles. */
.arc-Searchbar-icon-container-enabled.arc-Searchbar-icon-container {
    background-image: linear-gradient(315deg, #00c3ea, #0090d1);
}

/* disabled icon container styles. */
.arc-Searchbar-icon-container-disabled.arc-Searchbar-icon-container,
.arc-Searchbar-container-error .arc-Searchbar-icon-container {
    background: #c9d9e8;
    cursor: not-allowed;
}

/* shown options dropdown styles. */
.arc-Searchbar-dropdown {
    background: #fff;
    box-shadow: 0 2px 8px rgba(21, 41, 53, 0.32);
    opacity: 1;
    transition: opacity 0.3s;
    animation: arc-fade-in 0.3s ease;
}

/* hidden options dropdown styles. */
.arc-Searchbar-dropdown.arc-Searchbar-dropdown-hidden {
    pointer-events: none;
    opacity: 0;
}

/* dropdown option basic styles. */
.arc-Searchbar-dropdown-menu-item {
    display: flex;
    align-items: center;
    font-family: Roboto;
    line-height: 1.29;
    height: 40px;
    max-height: 40px;
    font-size: 14px;
    padding: 0 16px !important;
    color: #152935;
    border: solid 1px transparent;
    transition: border 0.3s ease;
}

/* active dropdown option styles. */
.arc-Searchbar-dropdown-menu-item.arc-Searchbar-dropdown-menu-item-active {
    border: solid 1px #0090d1;
}

/* top-level container styles (keeps all elements on one line). */
.arc-Searchbar-container {
    display: flex;
    max-height: 40px;
    width: 560px;
}

/* top-level container styles (keeps all elements on one line). */
.arc-Searchbar-container-error .arc-Searchbar-search__field {
    border: solid 1px #d82829;
}

/* search icon styles. */
.search-icon {
    /* 
     * z-index is needed in order to avoid hidden before pseudo 
     * element to cover the icon on searchbar's hover.
     */
    z-index: 1;
    font-size: 16px;
    color: #fff;
}

.arc-Searchbar-error-message {
    margin-top: 8px;
    font-family: Roboto;
    font-size: 12px;
    color: #d82829;
}

/* remove useless list styling. */
.arc-Searchbar-container ul,
.arc-Searchbar-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
